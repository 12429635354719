const refs = {
  controlMenuInner: document.querySelector('.js-control-menu-inner'),
  closeBtn: document.querySelector('[data-action="close-control-menu"]'),
};
const STORAGE_KEY = 'controlMenuHidden';
const controlMenuHeight = refs.controlMenuInner.offsetHeight;
const viewportWidth = document.documentElement.clientWidth;

if (viewportWidth < 1200) {
  document.body.style.paddingBottom = controlMenuHeight + 'px';
}

const isMenuHidden = localStorage.getItem(STORAGE_KEY) === 'true';
if (isMenuHidden) {
  refs.controlMenuInner.classList.add('hide');
  refs.closeBtn.classList.add('expand');
}

refs.closeBtn.addEventListener('click', toggleControlMenuInner);

function toggleControlMenuInner() {
  refs.closeBtn.classList.toggle('expand');
  refs.controlMenuInner.classList.toggle('hide');

  const isHidden = refs.controlMenuInner.classList.contains('hide');
  localStorage.setItem(STORAGE_KEY, isHidden);
}
