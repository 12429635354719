const lazyFrames = document.querySelectorAll('iframe[data-src]');

lazyFrames.forEach(frame => {
  frame.addEventListener('load', onLazyFrameLoaded, { once: true });
});

document.addEventListener('DOMContentLoaded', function () {
  const lazyLoad = function (entries, observer) {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        const iframe = entry.target;
        iframe.src = iframe.dataset.src;
        observer.unobserve(iframe); // Останавливаем наблюдение за iframe после его загрузки
      }
    });
  };

  const observer = new IntersectionObserver(lazyLoad, {
    rootMargin: '0px 0px 50px 0px', // Начинаем загружать за 50px до видимой области
    threshold: 0.1, // Процент видимости iframe до начала загрузки
  });

  lazyFrames.forEach(iframe => {
    observer.observe(iframe);
  });
});

function onLazyFrameLoaded(evt) {
  evt.target.parentNode.querySelector('.loading').classList.add('hide');
}
