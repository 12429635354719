import throttle from 'lodash.throttle';

const refs = {
  header: document.querySelector('.js-header'),
};

window.addEventListener('scroll', throttle(onWindowScroll, 250));

let previousPageYOffset = window.pageYOffset;
const breakpoint = 500;

function onWindowScroll(evt) {
  const currentPageYOffset = window.pageYOffset;

  if (
    currentPageYOffset > previousPageYOffset &&
    currentPageYOffset > breakpoint
  ) {
    showHeader();
  } else if (currentPageYOffset < previousPageYOffset) {
    hideHeader();
  }

  previousPageYOffset = currentPageYOffset;
}

function hideHeader() {
  refs.header.classList.remove('is-fixed');
  refs.header.classList.remove('fadeInDown');
}

function showHeader() {
  refs.header.classList.add('is-fixed');
  refs.header.classList.add('fadeInDown');
}
