import throttle from 'lodash.throttle';

const refs = {
  askBtn: document.querySelector('[data-action="ask-btn"]'),
};

let previousPageYOffset = window.pageYOffset;
const breakpoint = 500;

window.addEventListener('scroll', throttle(onWindowScroll, 250));
refs.askBtn.addEventListener('click', onAskBtnClick);

function onWindowScroll(evt) {
  const currentPageYOffset = window.pageYOffset;

  if (
    currentPageYOffset > previousPageYOffset &&
    currentPageYOffset > breakpoint
  ) {
    showAskBtn();
  } else if (currentPageYOffset < previousPageYOffset) {
    hideAskBtn();
  }

  previousPageYOffset = currentPageYOffset;
}

function onAskBtnClick(evt) {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
}

function showAskBtn() {
  refs.askBtn.classList.remove('is-hidden');
  refs.askBtn.classList.add('fadeInUp');
}

function hideAskBtn() {
  refs.askBtn.classList.add('is-hidden');
  refs.askBtn.classList.remove('fadeInUp');
}
